<template>
  <div class="plans h-100 d-flex flex-column">
    <div class="page py-0 py-sm-5 flex-grow-1 d-flex align-items-md-center py-5">
      <div class="container">
        <div class="page-container p-0 pb-5">
          <div class="row justify-content-center">
            <div class="d-none col-md-4 col-lg-3 d-md-flex align-items-start">
              <img src="../assets/mockup2x.png" alt="" class="w-100">
            </div>
            <div class="col-12 col-md-8 col-lg-6">
              <div class="subscription d-flex align-items-stretch border-1 border rounded mb-32">
                <div class="subscription-info w-50 position-relative pb-3 pt-4 ps-2 ps-sm-4
                d-flex flex-column align-items-start">
                  <p class="subscription__sale m-0 position-absolute
                  p-2 border ms-0 ms-sm-5 rounded-pill alert-success"
                     v-if="$store.state.cloaking === 'false'"
                  >
                    <span v-if="$store.state.cloaking === 'false'">
                      Special Offer!
                    </span>
                  </p>
                  <p class="subscription__sale m-0 position-absolute
                  p-2 border ms-0 ms-sm-5 rounded-pill alert-success"
                     v-if="isProdNew && $store.state.cloaking === 'true'"
                  >
                    <span>
                      SAVE -50%
                    </span>
                  </p>
                  <p class="subscription__trial mb-1">
                    <b>
                      3-day trial for {{chosenSubscription.before_timer.title[0]}}0.99
                    </b>
                  </p>
                  <div class="d-flex flex-column align-items-end"
                       v-if="$store.state.cloaking === 'true'">
                    <p class="subscription__new-price w-100 m-0 text-primary">
                      Then {{
                        isProdNew ? chosenSubscription.before_timer.title :
                          chosenSubscription.after_timer.title
                      }}
                    </p>
                    <p class="subscription__old-price
                    m-0 text-decoration-line-through text-muted" v-if="isProdNew">
                      {{ chosenSubscription.after_timer.title }}
                    </p>
                  </div>
                </div>
                <div class="subscription-timer w-50 d-flex
                justify-content-center align-items-center"
                     :class="{
                  'alert-success': isProdNew,
                  'alert-danger': !isProdNew,
                }">
                  <p class="m-0 fs-3">
                    0{{ this.minutes }}:{{
                      this.seconds.toString().length === 1 ?
                        `0${this.seconds}` : this.seconds
                    }}
                  </p>
                </div>
              </div>
              <h2 class="page__title mb-32 h1">
                Get top-notch math help in a flash!
              </h2>
              <ul class="hero-list ps-0 mb-5">
                <li class="hero-list__item d-flex align-items-center mb-3">
                  <img src="../assets/check_in_circle.svg" alt="">
                  <span class="mx-3 f-b-s">
                    1 detailed step-by-step solution to your math problem
                  </span>
                  <span class="hero-list__new"></span>
                </li>
                <li class="hero-list__item d-flex align-items-center mb-3">
                  <img src="../assets/check_in_circle.svg" alt="">
                  <span class="mx-3 f-b-s">
                    Access to secure private chat with a math expert assigned to you
                  </span>
                </li>
                <li class="hero-list__item d-flex align-items-center mb-3">
                  <img src="../assets/check_in_circle.svg" alt="">
                  <span class="mx-3 f-b-s">
                    Instant solution to your math tasks of any type and level
                  </span>
                </li>
                <li class="hero-list__item d-flex align-items-center">
                  <img src="../assets/check_in_circle.svg" alt="">
                  <span class="mx-3 f-b-s">
                    Just write your task or upload a photo of it!
                  </span>
                </li>
              </ul>
              <div class="d-grid gap-2 col-12 col-md-6">
                <button type="button" class="btn page-continue__btn" @click="payFormClick()">
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
        <p class="text-muted pe-4 text-center" v-if="$store.state.cloaking === 'true'">
          {{chosenSubscription.before_timer.title[0]}}0.99 charged today. If you don't cancel at
          least 24 hours before the end of the
          3-day trial period, you will automatically be charged the full price of
          {{
            isProdNew ? chosenSubscription.before_timer.title :
              chosenSubscription.after_timer.title
          }}
          . You can cancel your subscription at any time. By continuing, you indicate that
          you've read and agree to our
          <router-link to="terms" class="text-secondary">
            Terms & Conditions,
          </router-link>
          <router-link to="privacy" class="text-secondary">
            Privacy Policy
          </router-link>,
          <router-link to="money-back" class="text-secondary">
            Money Back
          </router-link>, and
          <router-link to="sub-terms" class="text-secondary">
            Subscription Terms
          </router-link>.
        </p>
      </div>
    </div>
    <billing-modal :is-modal-show="isBillingModalShow"
                   :product="actualProduct"
                   :chosen-subscription="chosenSubscription"
                   @onstatechange="onBillingModalClose"
    ></billing-modal>
  </div>
</template>

<script>
import BillingModal from '@/components/common/BillingModal.vue';
import { getCookie, setCookie } from '@/main';

export default {
  name: 'Plans',
  components: {
    BillingModal,
  },
  data() {
    return {
      isProdNew: true,
      minutes: getCookie('minutes') ? +getCookie('minutes') : 9,
      seconds: getCookie('seconds') ? +getCookie('seconds') : 59,
      isBillingModalShow: false,
      chosenSubscription: {
        before_timer: {
          title: '',
          uuid: '',
        },
        after_timer: {
          title: '',
          uuid: '',
        },
      },
    };
  },
  computed: {
    actualProduct() {
      return this.isProdNew
        ? this.chosenSubscription.before_timer.uuid
        : this.chosenSubscription.after_timer.uuid;
    },
  },
  methods: {
    payFormClick() {
      this.isBillingModalShow = true;
      window.fbq('track', 'InitiateCheckout');
    },
    onBillingModalClose() {
      this.isBillingModalShow = false;
    },
    timer() {
      return new Promise((resolve) => {
        const interval = setInterval(() => {
          if (this.minutes === 0 && this.seconds === 0) {
            clearInterval(interval);
            resolve('complete');
            this.isProdNew = false;
          } else if (this.seconds === 0) {
            this.seconds = 59;
            this.minutes -= 1;
            setCookie('minutes', this.minutes);
            setCookie('seconds', this.seconds);
          } else {
            this.seconds -= 1;
            setCookie('seconds', this.seconds);
          }
        }, 1000);
      });
    },
  },
  beforeMount() {
    if (getCookie('planchik')) {
      this.chosenSubscription = JSON.parse(getCookie('planchik'));
    } else {
      this.$store.dispatch('getProducts')
        .then((data) => {
          this.chosenSubscription = data.products;
          setCookie('planchik', JSON.stringify(this.chosenSubscription));
        });
    }
  },
  mounted() {
    document.documentElement.scrollTop = 0;
    this.timer()
      .then(() => {
      });
  },
};
</script>

<style lang="scss" scoped>
.page {
  background-color: #fff;

  * {
    font-family: 'DM Sans', sans-serif;
  }

  &__title {
    font-weight: bold;
    letter-spacing: -2px;
    color: #12022F;
    margin-bottom: 24px;
  }

  &-continue {
    &__btn {
      background: #5E5CE6;
      color: white;
      border-radius: 28px;
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      letter-spacing: -0.36px;
      padding-top: 16px;
      padding-bottom: 16px;
      text-transform: uppercase;

      &:hover {
        background: rgba(94, 92, 230, 0.85);
      }
    }
  }

  .subscription {
    &__sale {
      bottom: 100%;
      transform: translate(0, 50%);
    }
  }
}

.mb-32 {
  margin-bottom: 32px;
}
</style>
