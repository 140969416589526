<template>
  <div class="success-container container d-flex flex-column align-items-center position-relative">
    <div v-if="loaderShow"
         class="d-flex align-items-center
           justify-content-center
           position-absolute
           w-100 h-100
           bg-white" style="z-index: 1">
      <b-spinner variant="primary"></b-spinner>
    </div>
    <img class="success-image" src="../assets/400p.png" alt="">
    <h1 class="success-title">
      {{ message }}
    </h1>
    <template
      v-if="$store.state.credentials.email &&
       $store.state.credentials.password &&
       $store.state.credentials.deeplink &&
       $store.state.credentials.uuid"
    >
      <p class="text-center">
        <b>Login:</b>
        <span class="text-primary">
        {{ $store.state.credentials.email }}
      </span>
      </p>
      <p class="text-center">
        <b>Pass:</b>
        <span class="text-primary">
        {{ $store.state.credentials.password }}
      </span>
      </p>
      <a :href="`${$store.state.credentials.deeplink}&auth=${$store.state.credentials.uuid}`"
         class="btn btn-primary site-btn site-btn_primary">
        Continue
      </a>
    </template>
    <p class="text-center" v-else>
      If you didn't find our email with instructions, please check spam and other email folders
      or contact us via <a href="mailto:support@mathmaster.me">support@mathmaster.me</a>
    </p>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-unresolved
import * as Processing from 'processing';
import { getCookie, setCookie } from '@/main';

window.gtag('event', 'conversion', {
  send_to: 'AW-393469566/HM8WCOyV0YoDEP68z7sB',
  transaction_id: 'successPageView',
});
window.gtag('event', 'conversion', {
  send_to: 'AW-393469566/HM8WCOyV0YoDEP68z7sB',
  transaction_id: '17_12_21_expertchat.me_purchase_borys_success_code',
});
window.fbq('track', 'Purchase');

export default {
  name: 'Success',
  data() {
    return {
      loaderShow: true,
      message: '',
    };
  },
  methods: {
    transactionHandler() {
      this.$store.dispatch('checkTransactionStatus', window.localStorage.getItem('order_uuid'))
        .then((resp) => {
          this.message = resp.status;
          if (this.message === 'success') {
            this.getCredentials();
          }
          this.loaderShow = false;
        });
    },
    getCredentials() {
      return Processing.Processing.getCredentials()
        .then((credResp) => {
          this.$store.commit('setCredentials', credResp.data);
          setCookie('appLink',
            `${this.$store.state.credentials.deeplink}&auth=${this.$store.state.credentials.uuid}`);
        });
    },
  },
  created() {
    if (getCookie('appLink') && !this.$route.query.order_uuid) { // catch page reload
      window.open(getCookie('appLink'), '_self');
    } else { // solid/maxpay/paypal transaction handler
      setTimeout(this.transactionHandler, 3000);
    }
  },
};
</script>

<style lang="scss" scoped>
.success {
  &-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &-image {
    max-width: 450px;
    width: 100%;
  }

  &-title {
    font-size: 2rem;
  }

  &-button {
    font-size: 1rem;
  }
}

</style>
